import { graphql, Link } from "gatsby"
import React from "react"

import Layout from "../components/layout/index"
import { rhythm } from "../utils/typography"
import Tag from "../components/post/tags/tag"

const tagsTemplateStyles = {
  headerStyle: { textAlign: "center" },
  titleStyle: {
    marginTop: rhythm(1),
    marginBottom: 0,
    fontFamily: `Dosis`,
    fontWeight: 200,
  },
}

const Tags = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const { headerStyle, titleStyle } = tagsTemplateStyles
  const posts = data.allMarkdownRemark
    ? data.allMarkdownRemark.edges.map(({ node }) => node)
    : []

  const tags = [
    ...new Set(
      posts
        .map(({ frontmatter }) => frontmatter.tags)
        .filter(tags => tags && tags.length)
        .reduce((allTags, tags) => allTags.concat(tags))
    ),
  ].sort()

  return (
    <Layout location={location} title={siteTitle}>
      <article>
        <header style={headerStyle}>
          <h1 style={titleStyle}>Tags</h1>
        </header>
        <section>
          <div>
            <ol>
              {tags.map((tag, index) => (
                <li key={index}>
                  <Tag tag={tag} />
                </li>
              ))}
            </ol>
          </div>
          <div>
            <Link to={"/"} rel="prev">
              ← Home
            </Link>
          </div>
        </section>
      </article>
    </Layout>
  )
}

export default Tags

export const pageQuery = graphql`
  query BlogPostOnlyTags {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            tags
          }
        }
      }
    }
  }
`
